/* ScholarshipClosed.css */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
}

/* Style for the header (logo and text) */
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  margin-bottom: 20px;
}

.text {
  font-weight: bold;
  font-size: 18px;
  color: #333;
  line-height: 1.5;
}
